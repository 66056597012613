@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@300;400;700&display=swap');

* {
  font-family: "Comic Neue", cursive;
  font-style: normal;
}

#dexscreener-embed {
  position: relative;
  width: 100rem;
  padding-bottom: 40rem;
}

@media(min-width:1400px) {
  #dexscreener-embed {
    padding-bottom: 40rem;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 80rem;
  height: 40rem;
  top: 0;
  left: 10rem;
  border: 0;
}
